<template>
  <validation-observer ref="informationsGeneraux">
    <b-row>
      <b-col
        lg="12"
        class="pb-2"
      >
         <!-- EMPLOIS  -->
          <div>
            <b-card id="emplois">
              <b-card-header class="d-flex justify-content-between">
                <h3>EMPLOIS</h3>
              </b-card-header>
              <hr />
              <b-card-body>
                <!-- nouveeaux employés du mois  -->
                <div class="table-responsive text-center mt-3">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Salariés recrutés au cours du mois</th>
                        <th scope="col">Hommes</th>
                        <th scope="col">Femmes</th>
                        <th scope="col">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Nombre</th>
                        <td>
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="infos.nouveauEmplois.hommes"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                          >
                            <b-form-input
                              v-model.number="infos.nouveauEmplois.femmes"
                              type="number"
                              min="0"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </td>
                        <td>
                          {{
                            infos.nouveauEmplois.hommes +
                            infos.nouveauEmplois.femmes
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-card-body>
            </b-card>
          </div>
      </b-col>
    </b-row>

    <!-- Editions button -->
    <b-row v-if="showEditionButtons">
      <b-col
        cols="12"
        class="my-2 text-right"
      >
        <div class="">
          <b-button
            variant="outline-secondary"
            @click="onCancel"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            @click="submitForm"
          >
            Modifier
          </b-button>
        </div>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BFormRadioGroup,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  // BFormRadio,
  // BForm,
  // BCardText,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import { mapActions, mapState } from 'vuex'
import vSelect from 'vue-select'
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// imports for validation
// const store = require('store')

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BCardHeader,
    BCardBody,
    BFormRadioGroup,
    BCard,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    // BFormCheckbox,
    // BFormRadio,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    // BCardText,
    // BForm,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition], //
  data() {
    return {
      email,
      required,
      email,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,

      annees: [
        { name: '2019' },
        { name: '2020' },
        { name: '2021' },
        { name: '2022' },
        { name: '2023' },
      ],
      mois: [],
      departements: [],
      communes: [],
      arrondissements: [],
      quartiers: [],
      pays: [],
      types: [
        { text: 'Agence de voyage', value: 'Agence de voyage' },
        { text: 'Tour Operator', value: 'Tour Operator' },
        { text: 'Agence réceptive', value: 'Agence réceptive' },
      ],
      agrements: [
        { text: 'Non', value: 'Non' },
        { text: 'Oui', value: 'Oui' },
      ],

      infos: {
        annee: '',
        mois: '',
        departement: '',
        commune: '',
        arrondissement: '',
        quartier: '',
        nomEtablissement: '',
        numIFU: '',
        nomPromoteur: '',
        nationalitePromoteur: '',
        adresseComplete: '',
        telephone1: '',
        telephone2: '',
        telephone3: '',
        typeEtablissement: '',
        agrementMinistere: '',
        referenceAgrement: '',
        // employés
        employesSalaries: {
          hommeTempspleinNationaux: null,
          hommeTempspartielNationaux: null,
          hommeTempspleinEtrangers: null,
          hommeTempspartielEtrangers: null,
          femmeTempspleinNationaux: null,
          femmeTempspartielNationaux: null,
          femmeTempspleinEtrangers: null,
          femmeTempspartielEtrangers: null,
        },
        nouveauEmplois: {
          hommes: null,
          femmes: null,
        },
        employesNonSalaries: {
          hommeTempspleinNationaux: null,
          hommeTempspartielNationaux: null,
          hommeTempspleinEtrangers: null,
          hommeTempspartielEtrangers: null,
          femmeTempspleinNationaux: null,
          femmeTempspartielNationaux: null,
          femmeTempspleinEtrangers: null,
          femmeTempspartielEtrangers: null,
        },
        // renseignement exploitation
        voyagesInternes: {
          sansFrais: null,
          avecFrais: null,
        },
        voyagesInternationaux: {
          entreeSansFrais: null,
          entreeAvecFrais: null,
          sortieSansFrais: null,
          sortieAvecFrais: null,
        },
        recettesBrutes: {
          billetterie: null,
          voyagesOrganises: null,
          croisieresToutCompris: null,
          autresProduits: null,
        },
        commissions: {
          billetterie: null,
          voyagesOrganises: null,
          croisieresToutCompris: null,
          hebergement: null,
          locationAutomobile: null,
          primesAssurance: null,
          autresCommissions: null,
        },
        chargesSalariales: null,
        autresDepense: null,
      },
    }
  },
  computed: {
    ...mapState('params', {
      listPays: 'listPays',
      listMois: 'listMois',
      listAnnees: 'listAnnees',
    }),
  },
  props: {
    inputData: {
      type: Object,
      default: () => null,
    },
    showEditionButtons: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // this.pays = this.listPays
    // this.mois = this.listMois
    // this.annees = this.listAnnees
    

    // this.loadQuartier();
    if (this.inputData) {
      this.infos = this.inputData
    } else if (this.$route.params.data) {
      this.infos = this.$route.params.data
    } else {
      // this.infos = this.validOutput
    }
  },
  methods: {
    ...mapActions('params', {
      fetchAnnees: 'fetchAnnees',
    }),
    onCancel() {
      this.$emit('onCancel', {})
    },
    submitForm() {
      // this.$emit('onFormSuccess', this.infos)
      this.$refs.informationsGeneraux.validate().then(success => {
        if (success) {
          if (this.inputData) {
            this.$emit('onFormSuccess', this.infos)
          } else {
            this.$emit('onFormSucess', {
              routerParams: { preview: true, data: this.infos },
            })
          }

        }
      })
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/base/themes/bordered-layout.scss';
</style>
